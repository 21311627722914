import axios from 'axios';
import { RUNNING_MODE } from '../shared/constants';

let apiUrl = 'http://localhost:8000/api';
// let apiUrl = 'https://stg-monthly.ddrdataentry.com/api';

if (process.env.REACT_APP_MONTHLY_API_ENV === RUNNING_MODE.Staging) {
  apiUrl = 'https://stg-monthly.ddrdataentry.com/api';
} else if (process.env.REACT_APP_MONTHLY_API_ENV === RUNNING_MODE.Product) {
  apiUrl = 'https://monthly.ddrdataentry.com/api';
}
axios.defaults.headers['Access-Control-Allow-Headers'] = '*';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers['Access-Control-Allow-Methods'] = 'POST,GET,PUT,DELETE';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.delete['Access-Control-Allow-Origin'] = '*';


export default axios.create({
  baseURL: apiUrl
});
